div.nova {
  float: left;
  padding: 3px 8px;
}

div.nova.red.selected {
  background-color: red;
}
div.nova.green.selected {
  background-color: lightgreen;
}
div.nova.yellow.selected {
  background-color: yellow;
}
div.nova.orange.selected {
  background-color: orange;
}
